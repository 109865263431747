$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;

.harptabs {
    .please-wait {
        background: url(./img/ajax-loader.gif) no-repeat;
        width: 30px;
        height: 30px;
        display: none;  
        
        &.show {
            display: block;
        }
    }

    .tabs-edit-media {
        padding-top: 10px;
    }
    
    #harptabs-list-table
    {
        padding-top:20px;
        width: 100%;
        h2 {
            font-size: 20px;
        }        
    }
    
    form {
        .edit-panels {
            display: flex;
            justify-content: space-between;
        }
        .panel {
            flex-basis: 45%;
        }
    }

    table {
        th, td {
            &.ganre {
                @media(max-width: $md) {
                    display: none;
                }
            }
        }
        td {
            padding: 10px 0px;

            .button
            {
                width: 15px;
                height: 15px;
                
                &.media
                {
                    background: url(./img/media.gif) no-repeat;
                }  
                &.edit
                {
                    background: url(./img/edit.gif) no-repeat;
                } 
                &.delete
                {
                    background: url(./img/delete.gif) no-repeat;
                }             
            }            
        }

        &.tabs-list
        {
            width: 100%;

            .author
            {
                font-size: 0.98em;
                color: #9b9b9b;
            }

            .melody-name
            {
                display: flex;
                font-size: 16px;

                .media {
                    padding-left: 20px;
                }                
            }            
        }            
    }

    .medias-content
    {	
        text-align: center;
    }
    
    .tab-text
    {
        width: 100%;
    }  
    
    textarea
    {
        font-family: monospace;
    }    

    .list-panel {
        display: flex;

        @media(max-width: $md) {
            flex-wrap: wrap;            
        }

        .panel {
            &.tablist {
                flex: 1 1 0;
            }
            &.control {
                flex-basis: 30%;
                @media(max-width: $md) {
                    flex: 1 1 0;
                }
            }  
            &:first-child {
                padding-right: 20px;
            }   
        }  
    }

    nav.pagination {
        padding-top: 20px;
        
        &.current {
            background-color: black;
            color: white;
            padding: 5px;
        }        
    }
}

article.tabs {
    .entry-header h1
    {
        padding-bottom: 0px;
            margin-bottom: 0px;
    }

    .entry-content
    {
        padding-top: 0px;

        h2 {
            padding-top: 0px;
            font-size: 1.2em;
            font-weight: normal;    
        }

        h3 {
            font-size: 1.2em;
        }            

        .tabs {
            display: flex;
            flex-wrap: wrap;

            .tabs-meta {
                padding-left: 20px;
            }
            .tabs-media {
                padding-top: 20px;
                .tabs-media-item {
                    padding: 10px 0px;
                }                
            }                        
        }
        .tabs-content {
            overflow-x: scroll;
            background-color: #eee;
            padding: 10px;
            border-radius: 5px;

            .tabs-melody-author{
                padding-bottom: 10px;
                font-weight: bold;
            }            
        } 
    }
}

