.harptabs .please-wait {
  background: url(./img/ajax-loader.gif) no-repeat;
  width: 30px;
  height: 30px;
  display: none;
}
.harptabs .please-wait.show {
  display: block;
}
.harptabs .tabs-edit-media {
  padding-top: 10px;
}
.harptabs #harptabs-list-table {
  padding-top: 20px;
  width: 100%;
}
.harptabs #harptabs-list-table h2 {
  font-size: 20px;
}
.harptabs form .edit-panels {
  display: flex;
  justify-content: space-between;
}
.harptabs form .panel {
  flex-basis: 45%;
}
@media (max-width: 768px) {
  .harptabs table th.ganre, .harptabs table td.ganre {
    display: none;
  }
}
.harptabs table td {
  padding: 10px 0px;
}
.harptabs table td .button {
  width: 15px;
  height: 15px;
}
.harptabs table td .button.media {
  background: url(./img/media.gif) no-repeat;
}
.harptabs table td .button.edit {
  background: url(./img/edit.gif) no-repeat;
}
.harptabs table td .button.delete {
  background: url(./img/delete.gif) no-repeat;
}
.harptabs table.tabs-list {
  width: 100%;
}
.harptabs table.tabs-list .author {
  font-size: 0.98em;
  color: #9b9b9b;
}
.harptabs table.tabs-list .melody-name {
  display: flex;
  font-size: 16px;
}
.harptabs table.tabs-list .melody-name .media {
  padding-left: 20px;
}
.harptabs .medias-content {
  text-align: center;
}
.harptabs .tab-text {
  width: 100%;
}
.harptabs textarea {
  font-family: monospace;
}
.harptabs .list-panel {
  display: flex;
}
@media (max-width: 768px) {
  .harptabs .list-panel {
    flex-wrap: wrap;
  }
}
.harptabs .list-panel .panel.tablist {
  flex: 1 1 0;
}
.harptabs .list-panel .panel.control {
  flex-basis: 30%;
}
@media (max-width: 768px) {
  .harptabs .list-panel .panel.control {
    flex: 1 1 0;
  }
}
.harptabs .list-panel .panel:first-child {
  padding-right: 20px;
}
.harptabs nav.pagination {
  padding-top: 20px;
}
.harptabs nav.pagination.current {
  background-color: black;
  color: white;
  padding: 5px;
}

article.tabs .entry-header h1 {
  padding-bottom: 0px;
  margin-bottom: 0px;
}
article.tabs .entry-content {
  padding-top: 0px;
}
article.tabs .entry-content h2 {
  padding-top: 0px;
  font-size: 1.2em;
  font-weight: normal;
}
article.tabs .entry-content h3 {
  font-size: 1.2em;
}
article.tabs .entry-content .tabs {
  display: flex;
  flex-wrap: wrap;
}
article.tabs .entry-content .tabs .tabs-meta {
  padding-left: 20px;
}
article.tabs .entry-content .tabs .tabs-media {
  padding-top: 20px;
}
article.tabs .entry-content .tabs .tabs-media .tabs-media-item {
  padding: 10px 0px;
}
article.tabs .entry-content .tabs-content {
  overflow-x: scroll;
  background-color: #eee;
  padding: 10px;
  border-radius: 5px;
}
article.tabs .entry-content .tabs-content .tabs-melody-author {
  padding-bottom: 10px;
  font-weight: bold;
}